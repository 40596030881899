import React, { useEffect, useState, useRef } from "react";

interface AutoCompleteLocationProps {
  value: string;
  onChange: (address: string) => void;
  placeholder?: string;
  className?: string;
}

const AutoCompleteLocation: React.FC<AutoCompleteLocationProps> = ({
  value,
  onChange,
  placeholder = "Enter a location",
  className = "input",
}) => {
  const autoCompleteRef = useRef<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    // Get API key from the environment variable
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    if (!apiKey) {
      console.error("Google Maps API key not found in environment variables");
      return;
    }

    // Load Google Places script if it's not already loaded
    if (!window.google) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      script.async = true;
      script.onload = () => setScriptLoaded(true);
      document.head.appendChild(script);
    } else {
      setScriptLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (scriptLoaded && inputRef.current) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          componentRestrictions: { country: ["us", "ca"] }, // Restrict to USA and Canada
        },
      );

      autoCompleteRef.current.addListener("place_changed", () => {
        const place = autoCompleteRef.current.getPlace();
        if (place.formatted_address) {
          onChange(place.formatted_address);
        }
      });
    }
  }, [scriptLoaded]);

  return (
    <input
      ref={inputRef}
      type="text"
      value={value}
      onChange={e => onChange(e.target.value)}
      placeholder={placeholder}
      className={className}
    />
  );
};

export default AutoCompleteLocation;
