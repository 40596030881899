import React, { useState } from "react";
import { useAuth } from "context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button } from "components/Button/Button";
import "./Signup.scss";
import { toast } from "react-toastify";
import { AuthSchema } from "validation/schemas";
import LoadingScreen from "components/LoadingScreen/LoadingScreen";
import { ReactComponent as IconLock } from "images/lock.svg";
import { ReactComponent as IconMessage } from "images/message.svg";
import { ReactComponent as IconEyeClosed } from "images/eye_closed.svg";
import { ReactComponent as IconEye } from "images/eye.svg";
import { ReactComponent as IconLogo } from "images/big_logo.svg";
import { trackNewSignup } from "utils/mixpanel";

const Signup: React.FC = () => {
  const { signup, loginWithGoogle } = useAuth();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  const handleSubmit = async (values: { email: string; password: string }) => {
    setLoading(true);
    try {
      await signup(values.email, values.password);
      toast.success("Account created successfully.");
      trackNewSignup(values.email);
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (err) {
      toast.error("Failed to create an account.");
    }
    setLoading(false);
  };

  const handleGoogleSignup = async () => {
    setLoading(true);
    try {
      const res = await loginWithGoogle();
      const email = res?.user?.email;
      toast.success("Account created with Google successfully.");
      trackNewSignup(email);
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (err) {
      toast.error("Failed to sign up with Google.");
    }
    setLoading(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  if (loading) return <LoadingScreen />;

  return (
    <div className="auth-page">
      {step === 1 ? (
        // Step 1: Informative screen
        <div className="form-wrapper primary-bg">
          <div className="informative-screen form-container">
            <div className="title-subtitle">
              <IconLogo className="big-logo" />
              <div className="title contrast center">
                Welcome to the EMPWR Trucking Assistant!
              </div>
            </div>
            <div className="buttons-container">
              <Button
                text="Get started"
                variant="contrast"
                onClick={() => setStep(2)}
              />
            </div>
          </div>
        </div>
      ) : (
        // Step 2: Signup form
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={AuthSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="form-wrapper">
              <div className="form-screen form-container">
                <div className="fields-container">
                  <div>
                    <div className="title">Let’s Get Started!</div>
                    <div className="subtitle">
                      Enter your credentials to continue
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-wrapper">
                      <IconMessage className="input-icon" />
                      <Field
                        type="email"
                        name="email"
                        className="input"
                        placeholder="Email"
                      />
                    </div>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="form-group">
                    <div className="input-wrapper">
                      <IconLock className="input-icon" />
                      <Field
                        type={showPassword ? "text" : "password"}
                        name="password"
                        className="input"
                        placeholder="Password"
                      />
                      <div
                        className="input-icon-right"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <IconEyeClosed /> : <IconEye />}
                      </div>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <Button text="Sign up" type="submit" />

                  <div className="or-divider">
                    <div className="divider" />
                    <div className="text">or</div>
                    <div className="divider" />
                  </div>

                  <Button
                    text="Continue with Google"
                    type="button"
                    className="google-button"
                    onClick={handleGoogleSignup}
                    google
                  />
                </div>

                <div className="bottom-text">
                  <div className="text">Already have an account? </div>
                  <div className="link" onClick={() => navigate("/login")}>
                    Log in
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </div>
  );
};

export default Signup;
