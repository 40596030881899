import React, { useState } from "react";
import { useAuth } from "context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button } from "components/Button/Button";
import * as Yup from "yup";
import "../Signup/Signup.scss";
import { toast } from "react-toastify";
import LoadingScreen from "components/LoadingScreen/LoadingScreen";
import { ReactComponent as IconLock } from "images/lock.svg";
import { ReactComponent as IconMessage } from "images/message.svg";
import { ReactComponent as IconEyeClosed } from "images/eye_closed.svg";
import { ReactComponent as IconEye } from "images/eye.svg";
import { getUserProfile } from "api/userService";

const Login: React.FC = () => {
  const { login, resetPassword, loginWithGoogle } = useAuth();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false); // Toggle for forgot password
  const navigate = useNavigate();

  const handleSubmit = async (values: { email: string; password?: string }) => {
    setLoading(true);
    if (forgotPassword) {
      try {
        await resetPassword(values.email);
        toast.success("Password reset email sent.");
      } catch (err) {
        toast.error("Failed to send password reset email.");
      }
    } else {
      try {
        await login(values.email, values.password!);
        toast.success("Logged in successfully.");
        const response = await getUserProfile(values.email);

        setTimeout(() => {
          navigate("/");
        }, 2000);
      } catch (err) {
        toast.error("Failed to log in. Please check your credentials.");
      }
    }
    setLoading(false);
  };

  const handleGoogleLogin = async () => {
    setLoading(true);
    try {
      await loginWithGoogle();
      toast.success("Logged in with Google successfully.");
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (err) {
      toast.error("Failed to log in with Google.");
    }
    setLoading(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  const getValidationSchema = (forgotPassword: boolean) => {
    return Yup.object().shape({
      email: Yup.string().email("Invalid email").required("Email is required"),
      password: forgotPassword
        ? Yup.string().nullable() // No validation for password in forgot password mode
        : Yup.string()
          .min(6, "Password must be at least 6 characters")
          .required("Password is required"),
    });
  };

  if (loading) return <LoadingScreen />;

  return (
    <div className="auth-page">
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={getValidationSchema(forgotPassword)}
        onSubmit={handleSubmit}
      >
        <Form>
          <div className="form-wrapper">
            <div className="form-screen form-container">
              <div className="fields-container">
                <div>
                  <div className="title">
                    {forgotPassword ? "Forgot Password?" : "Welcome Back!"}
                  </div>
                  <div className="subtitle">
                    {forgotPassword
                      ? "Enter your email to reset your password"
                      : "Please enter your details"}
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-wrapper">
                    <IconMessage className="input-icon" />
                    <Field
                      type="email"
                      name="email"
                      className="input"
                      placeholder="Email"
                    />
                  </div>
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-message"
                  />
                </div>
                {!forgotPassword && (
                  <div className="form-group">
                    <div className="input-wrapper">
                      <IconLock className="input-icon" />
                      <Field
                        type={showPassword ? "text" : "password"}
                        name="password"
                        className="input"
                        placeholder="Password"
                      />
                      <div
                        className="input-icon-right"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <IconEyeClosed /> : <IconEye />}
                      </div>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error-message"
                    />
                  </div>
                )}
                <div
                  className="forgot-password-link"
                  onClick={() => setForgotPassword(!forgotPassword)}
                >
                  {forgotPassword ? "Log in" : "Reset Password"}
                </div>
                <Button
                  text={forgotPassword ? "Reset Password" : "Log in"}
                  type="submit"
                />

                {!forgotPassword && (
                  <>
                    <div className="or-divider">
                      <div className="divider" />
                      <div className="text">or</div>
                      <div className="divider" />
                    </div>

                    <Button
                      text="Continue with Google"
                      type="button"
                      className="google-button"
                      onClick={handleGoogleLogin}
                      google
                    />
                  </>
                )}
              </div>
              {!forgotPassword && (
                <div className="bottom-text">
                  <div className="text">Don’t have an account? </div>
                  <div className="link" onClick={() => navigate("/signup")}>
                    Sign up
                  </div>
                </div>
              )}
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default Login;
