import "styles/global.scss";
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Onboarding from "pages/Onboarding/Onboarding";
import RequestPage from "pages/RequestPage/RequestPage";
import SettingsPage from "pages/SettingsPage/SettingsPage";
import { useUser } from "hooks/useUser";
import LoadingScreen from "components/LoadingScreen/LoadingScreen";
import Home from "pages/Home/Home";
import EditFieldPage from "pages/SettingsPage/EditFieldPage";
import Login from "pages/Login/Login";
import Signup from "pages/Signup/Signup";
import ProtectedRoute from "components/ProtectedRoute";
import { useAuth } from "context/AuthContext";
import mixpanel from "mixpanel-browser";

const App: React.FC = () => {
  const { user, loading } = useUser();
  const { currentUser } = useAuth();
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || "", {
    ignore_dnt: true,
  });


  useEffect(() => {
    const updateVhUnit = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    updateVhUnit();
    window.addEventListener("resize", updateVhUnit);

    return () => {
      window.removeEventListener("resize", updateVhUnit);
    };
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Router>
      <Routes>
        {/* Public routes */}
        <Route
          path="/login"
          element={currentUser ? <Navigate to="/" /> : <Login />}
        />
        <Route
          path="/signup"
          element={currentUser ? <Navigate to="/" /> : <Signup />}
        />

        {/* Protected routes */}
        <Route
          path="/"
          element={
            <ProtectedRoute>
              {user ? <Home /> : <Navigate to="/onboarding" />}
            </ProtectedRoute>
          }
        />

        <Route
          path="/onboarding"
          element={
            <ProtectedRoute>
              <Onboarding />
            </ProtectedRoute>
          }
        />

        <Route
          path="/request"
          element={
            <ProtectedRoute>
              <RequestPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <SettingsPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/settings/edit/:fieldKey"
          element={
            <ProtectedRoute>
              <EditFieldPage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
