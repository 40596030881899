import { toast } from "react-toastify";
import apiClient from "./config";

export const submitLaneSearch = async (data: any) => {
  try {
    const response = await apiClient.post("ai-caller/submit-lane-search", data);
    return response.data;
  } catch (error) {
    toast.error(
      `Failed to submit the search. ${(error as any)?.response?.data?.error || ""}`,
    );
    throw new Error("Failed to submit lane search");
  }
};
